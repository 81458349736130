import React, { useEffect } from "react"
import { withStyles } from "@material-ui/core"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"

import Reveal from "reveal.js"
import { useGatsbyImagePath, useFluidUrl } from "../images"

import { ThemeProvider } from "@material-ui/styles"
import theme from "./../../presentation-theme"
import Typography from "@material-ui/core/Typography"
import { Clock } from "../svg/clock"
import { AstraloadLogo } from "../svg/astraload-logo"
import { PresentationAstraloadLogo } from "../svg/presentation-astraload-logo"
import {
  PresentationSvg1,
  PresentationSvg2,
  PresentationSvg3,
  PresentationSvg4,
  PresentationSvg5,
  PresentationSvg6,
  PresentationSvg7,
  PresentationSvg8,
  SvgArrowRight,
  SvgAstraloadCompany,
} from "../svg/presentation-svg"



let presentation

function AstraloadFrontendPresentation(props) {
  const { classes, prWidth, width } = props
  const figmaLogo = useFluidUrl("figmaLogo")
  const storybookLogo = useFluidUrl("storybookLogo")
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    presentation = new Reveal({
      embedded: true,
      width: prWidth,
      progress: false,
      controls: false,
      overview: false,
      height: 900
    })
    presentation.initialize().then(()=>{
      //presentation.slide(2)
    })
  }, [])

  function onNextButtonClick() {
    if (presentation) {
      presentation.next()
    }
  }

  function renderNextButton(isAnimate = false) {
    const animateClass = isAnimate ? "fragment" : ""
    return (
      <div className={classes.footerButton}>
        <div className={classes.nextButton + ` ${animateClass}`} onClick={onNextButtonClick}>
          NEXT
        </div>
      </div>
    )
  }

  function renderHelpSlide() {
    if (isWidthDown('sm', width)) {
      return (
        <div
          className={classes.sectionContent}
          style={{ alignItems: "flex-end" }}
        >
          <SvgAstraloadCompany className={classes.phaseImgTop + ` ${classes.phaseImgTopMobile}`} />
          <div className={classes.swipeBlock}>
            <Typography variant="h1" className={classes.swipeBlockTitle}>
              Листайте вправо
            </Typography>
            <Typography variant="body1" className={classes.swipeBlockText}>
              Горизонтальный вид <br/> предпочтительнее
            </Typography>
            <SvgArrowRight className={classes.arrowRight} />
          </div>
        </div>
      )
    }

    return (
      <div className={classes.sectionContent}>
        <Typography
          variant="h1"
          className={classes.introduceTitle}
          style={{maxWidth: "1000px"}}
        >
          Нажмите <div className={classes.nextButtonExplain} onClick={onNextButtonClick}>NEXT</div> или стрелку вправо, чтобы прочитать подробности программы
        </Typography>
        <PresentationSvg8
          className={classes.astraloadSvgImage}
        />
      </div>
    )
  }


  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className="reveal">
          <div className="slides">
            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}
              className={classes.section}
            >
              {renderHelpSlide()}
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}

              className={classes.section}
            >
              <div className={classes.sectionContentFromTop}>
                <div className={classes.introduceSlide}>
                  <AstraloadLogo className={classes.introduceLogo}/>
                  <Typography
                    variant="h1"
                    className={classes.introduceTitle}
                    style={{minWidth: "960px", maxWidth:"960px"}}
                  >
                    Фронтенд программа от Астралоад — Дизайн система, Сторибук и Тестирование
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.introduceText}
                    style={{minWidth: "960px", maxWidth:"960px", letterSpacing: 0}}
                  >
                    Команда профессионалов в сфере дизайна, разработки и фасилитации за 12 недель отмасштабирует ваш процесс разработки фронтенда при помощи современных инструментов, надёжных процессов и командного коучинга, а также на протяжении всего этого времени будет непосредственно участвовать в дизайне и разработке фронтенда
                  </Typography>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#8C40FF"
              />
              <div className={classes.sectionContent}>
                <div className={classes.firstSlideTextContainer}>
                  <div
                    className={classes.textBlock + ` ${classes.textContainer}`}
                  >
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3}>
                        Два фронтенд разработчика, дизайнер и фасилитатор
                      </Typography>
                    </div>
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3} style={{minWidth: "268px", maxWidth:"268px"}}>
                        В течение 12-ти недель
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={classes.textBlock + ` ${classes.textContainer}`}
                  >
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3} style={{minWidth: "500px", maxWidth:"500px"}}>
                        На основе программы, с любовью спроектированной командой Astraload
                      </Typography>
                    </div>
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3} style={{minWidth: "550px", maxWidth:"550px"}}>
                        Создадут дизайн, разработают фронтенд, внедрят лучшие практики и обучат вашу команду
                      </Typography>
                    </div>
                  </div>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#8C40FF"
              />
              <div className={classes.sectionContentFromTop}>
                <div className={classes.phasesContainer}>
                  <div className={classes.phasesMenuContainer}>
                    <div className={classes.phasesTitle}>Фазы</div>
                    <div className={classes.phasesMenu}>
                      <div
                        className="fragment fade-out"
                        data-fragment-index={0}
                      >
                        Аудирование и планирование
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={0}
                      >
                        Создание Дизайн Системы
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={1}
                      >
                        Настройка библиотеки компонентов в Storybook
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={2}
                      >
                        Настройка процессов передачи дизайнов
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={3}
                      >
                        Представление проверки реализации пользовательского интерфейса и скриншот тестирование
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={4}
                      >
                        Разработка адаптивных пользовательских интерфейсов
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={5}
                      >
                        Разработка фронтенд бизнес-логики при помощи подходов DDD
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={6}
                      >
                        Unit-тестирование фронтенд бизнес-логики
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={7}
                      >
                        Настройка проверки работоспособности API (sanity checking)
                      </div>
                    </div>
                  </div>
                  <div className={classes.phasesDescriptionContainer}>
                    <div className={classes.phasesDescription}>
                      <div className="r-stack">
                        <div
                          className="fragment fade-out"
                          data-fragment-index={0}
                        >
                          <PresentationSvg1
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 неделя
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "686px", maxWidth:"686px"}}>
                            Эффективная коммуникация между Astraload и вашей командой
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "686px", maxWidth:"686px"}}>
                            Список компонентов пользовательского интерфейса и их вариантов
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={0}
                        >
                          <img
                            src={figmaLogo.src}
                            className={classes.phaseImg}
                            width="127"
                            alt=""
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1–2 недели
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Команда дизайнеров, знающая, как построить дизайн систему
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Основа дизайн системы, реализованная в Figma: цвета, типографика, система отступов, система компонентов
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={1}
                        >
                          <img
                            src={storybookLogo.src}
                            className={classes.phaseImg}
                            alt=""
                            width="187"
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1–2 недели
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "780px", maxWidth:"780px"}}>
                            Команда разработчиков, знающая, как использовать Storybook для построения библиотеки компонентов
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Библиотека компонентов, настроенная в Storybook
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={2}
                        >
                          <PresentationSvg2
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 неделя
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Команда дизайна изучит, как использовать CSS Grid, Flexbox и DoD компонентов в обсуждении, реализации дизайна и передаче его разработчикам
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "737px", maxWidth:"737px"}}>
                            Дизайны компонентов, выполненные в Figma
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={3}
                        >
                          <PresentationSvg3
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 неделя
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "789px", maxWidth: "789px", fontSize: "32px", lineHeight: "40px"}}>
                            Команда разработчиков узнает, как использовать скриншот тестирование и как создать пользовательский интерфейс, попиксельно соотвествующий дизайну (pixel-perfect UI)
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "780px", maxWidth: "780px", fontSize: "32px", lineHeight: "40px"}}>
                            Сложные компоненты пользовательского интерфейса будут выполнены в Storybook, реализованы с pixel-perfect качеством и подвергнуты тщательному контролю через скриншот тестированию
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={4}
                        >
                          <PresentationSvg4
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1–2 недели
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Команда дизайнеров изучит, как создавать сложные интерфейсы с использованием Atomic Design и UX паттернов
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Дизайн страниц, реализованный в Figma
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={5}
                        >
                          <PresentationSvg5
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 2 недели
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Команда разработки узнает, как выстраивать иерархию компонентов, следуя принципам Atomic design, определения интерфейсов компонентов и использования Bounded Contexts
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "588px", maxWidth: "588px"}}>
                            Реализация бизнес-логики пользовательского интерфейса в Storybook
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={6}
                        >
                          <PresentationSvg6
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 неделя
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Команда разработчиков изучит эффективные и понятные, приятные в использовании техники unit-тестирования бизнес-логики во фронтенде
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "708px", maxWidth: "708px"}}>
                            Бизнес-логика интерфейса, покрытая unit-тестами
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={7}
                        >
                          <PresentationSvg7
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 неделя
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Результат командной тренировки:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Команда разработчиков изучит, как защитить фронтенд от внезапного изменения серверного API
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Технический результат:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3} style={{minWidth: "780px", maxWidth: "780px"}}>
                            Бизнес-логика внешнего фронтенда, защищённая от внезапного изменения серверного API
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "effortsStepsBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#FF2C9C"
              />
              <div className={classes.sectionContent}>
                <div>
                  <Typography variant="h3" className={classes.titleH3White}>
                    Каждый этап состоит из следующих мероприятий:
                  </Typography>

                  <div className={classes.stepsList}>
                    <div
                      className={classes.stepItem + " fragment fade-out"}
                      data-fragment-index={0}
                    >
                      Планирование
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={0}
                    >
                      Техническая деятельность
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={2}
                    >
                      Обучение
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={3}
                    >
                      Практика
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={4}
                    >
                      Отчетность
                    </div>
                  </div>

                  <div className="r-stack">
                    <div style={{width: "100%"}}>
                      <Typography
                        variant="h1"
                        className={classes.title + " fragment fade-out"}
                        data-fragment-index={0}
                        style={{minWidth: "836px", maxWidth: "836px"}}
                      >
                        Мы рассказываем, что мы собираемся делать, и составляем план для этапа
                      </Typography>
                    </div>

                    <Typography
                      variant="h1"
                      className={classes.title + " fragment fade-in-then-out"}
                      data-fragment-index={0}
                      style={{minWidth: "1308px", maxWidth: "1308px", letterSpacing: 0}}
                    >
                      Техническая команда разрабатывает технологии, процессы и лучшие практики. Команда также выполняет обычную работу дизайнеров и разработчиков
                    </Typography>

                    <div
                      className={
                        classes.section2EqualContainer +
                        " fragment fade-in-then-out"
                      }
                      data-fragment-index={2}
                    >
                      <Typography
                        variant="h1"
                        className={classes.title}
                        style={{minWidth: "652px", maxWidth: "652px", letterSpacing: 0, fontSize: "56px", lineHeight: "64px", marginRight: "69px"}}
                      >
                        Фасилитатор обучает{" "}
                        <span className={classes.highlightRed}>вашу команду</span>{" "}
                        с помощью материалов, релевантных текущему этапу
                      </Typography>
                      <Typography
                        variant="h1"
                        className={classes.title}
                        style={{minWidth: "590px", maxWidth: "590px", letterSpacing: 0, fontSize: "56px", lineHeight: "64px"}}
                      >
                        Если у вас нет свободной frontend команды, мы можем предоставить её
                      </Typography>
                    </div>
                    <Typography
                      variant="h1"
                      className={classes.title + " fragment fade-in-then-out"}
                      data-fragment-index={3}
                    >
                      Технические специалисты тесно сотрудничают с вашей командой, чтобы практиковать и институционализировать новые процессы и стандарты
                    </Typography>
                    <Typography
                      variant="h1"
                      className={classes.title + " fragment fade-in-then-out"}
                      data-fragment-index={4}
                      style={{minWidth: "985px", maxWidth: "985px", letterSpacing: 0, marginLeft: 0}}
                    >
                      Фасилитатор представляет результаты этапа стейкхолдерам
                    </Typography>
                  </div>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationContactsBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#FFFFFF"
              />
              <div className={classes.sectionContent}>
                <div className={classes.contacts}>
                  <Typography
                    variant="h1"
                    className={classes.contactsTitle}
                    style={{minWidth: "1151px", maxWidth: "1151px", letterSpacing: 0, fontSize: "56px"}}
                  >
                    Astraload — это небольшая команда опытных профессионалов,
                    наши услуги исключительно эксклюзивны — <a href="https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform" target="_blank">
                       забронируйте команду прямо сейчас
                    </a>, пока она доступна
                  </Typography>

                  <div className={classes.contactsFooter}>
                    <Typography variant="body1" className={classes.contactsInfo}>
                      Остались вопросы?
                    </Typography>
                    <div className={classes.contactsLinks}>
                      <a href="https://blog.astraloadhq.com/" target="_blank">Наш блог</a>
                      <a href="https://t.me/joinchat/DQIDQU7Naz7doknz7u8meQ" target="_blank">Группа в телеграме</a>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform" target="_blank">Рассчитать стоимость</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.copyright + ` ${classes.copyrightWhite}`}>© {currentYear} Astraload, Inc.</div>
            </section>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

const styles = theme => {
  const flexContainer = {
    display: "flex",
    justifyContent: "space-between",
  }

  return {
    container: {
      height: "100%",
      position: "relative",

      "& *": {
        fontFamily: "Inter, sans-serif",
      },
    },
    iframe: {
      border: "none",
    },
    section: {
      height: "100%",
    },
    sectionContent: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    sectionContentFromTop: {
      height: "100%",
      display: "flex",
      paddingTop: 24
    },
    footerButton: {
      position: "absolute",
      bottom: 24,
      left: "50%",
      transform: "translate(-50%, 0)"
    },
    textBlock: {
      display: "flex",

      "& $titleH3": {
        margin: 0,
        maxWidth: 360
      },

      "&:not(:last-child)": {
        marginBottom: 48
      }
    },
    firstSlideTextContainer: {
      width: "100%",
      maxWidth: 952,
      margin: "0 auto"
    },
    phasesContainer: {
      display: "flex",
      textAlign: "left",
      width: "100%"
    },
    phasesTitle: {
      fontWeight: 700,
      color: "#373340",
      fontSize: 24,
      lineHeight: "32px",
      marginBottom: 40,
    },
    phasesDuration: {
      fontWeight: 700,
      color: "#373340",
      fontSize: 24,
      lineHeight: "32px",
      marginBottom: 32,
      position: "relative",
      display: "flex",
      alignItems: "center"
    },
    clockIcon: {
      marginRight: 16,
      display: "flex",
      alignItems: "center"
    },
    phasesSubtitle: {
      fontSize: 24,
      fontWeight: 400,
      color: "#75038A",
      marginBottom: 16
    },
    phasesMenuContainer: {
      maxWidth: 424,
      width: "100%",
      marginRight: 48
    },
    phasesDescriptionContainer: {
      width: "100%",

      "& .r-stack > *": {
        margin: "0 !important"
      }
    },
    phasesMenu: {
      minWidth: "424px",

     "& > *" : {
       fontWeight: 500,
       fontSize: 18,
       lineHeight: "24px",
       transition: "none !important",

       "&:not(:last-child)": {
         marginBottom: 24
       },

       "&.highlight-current-red.current-fragment": {
         fontSize: 26,
         lineHeight: "32px",
         color: "#75038A !important",
       },
       "&.fade-out:not(.visible)": {
         fontSize: 26,
         lineHeight: "32px",
         color: "#75038A !important",
       },
       "&.fragment.visible.fade-out": {
         opacity: "1 !important",
         visibility: "visible !important",
       },
     }
    },
    phaseImg: {
      position: "absolute",
      userSelect: "none",
      right: 0,
      bottom: 10,
      zIndex: -1,

      [theme.breakpoints.down("sm")]: {
        transform: "scale(0.7)",
        transformOrigin: "right center"
      },
    },
    section2EqualContainer: {
      ...flexContainer,

      "& > *": {
        width: "48%",
      },
    },
    title: {
      "&.highlight-red.visible": {
        color: "#F7D4F6 !important",
      },
    },
    titleH3: {
      color: "#75038A",
      margin: 0,
      minWidth: 360,
      paddingRight: 20,

      "&:not(:last-child)": {
        marginBottom: 40
      }
    },
    titleH3White: {
      color: theme.palette.text.white,
      maxWidth: "660px",
    },
    textContainer: {
      ...flexContainer,

      "& > *": {
        width: "42%",
      },
    },
    stepsList: {
      display: "flex",
      margin: "48px 0",

      "& + $title": {
        maxWidth: 730,
      },
    },
    stepItem: {
      padding: "6px 12px",
      background: theme.palette.text.white,
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      fontSize: 16,
      margin: 0,
      borderRadius: 12,

      "&:not(:last-child)": {
        marginRight: 24,
      },

      "&.highlight-current-red.current-fragment": {
        backgroundColor: "#75038A",
        color: "#ffffff !important",
      },
      "&.fade-out:not(.visible)": {
        backgroundColor: "#75038A",
        color: "#ffffff !important",
      },
      "&.fragment.visible.fade-out": {
        opacity: "1 !important",
        visibility: "visible !important",
      },
    },
    highlightRed: {
      color: "#FF2C9C",
    },
    contacts: {
      textAlign: "left",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "92px 48px",
      boxSizing: "border-box"
    },
    contactsTitle: {
      margin: "0 0 32px 0",
      color: theme.palette.text.white,

      "& a": {
        fontSize: 60,
        lineHeight: "72px",
        fontWeight: 700,
        textDecoration: "underline",
        "&:hover": {
          opacity: 0.8
        },
      }
    },
    topNote: {
      marginBottom: 90,
      color: theme.palette.text.white,
      maxWidth: 760,
      fontSize: 24,
      fontWeight: 400
    },
    contactsSubtitle: {
      margin: "0 0 24px 0",
    },
    contactsInfo: {
      color: theme.palette.text.white,
      fontWeight: "normal",

      "& a": {
        textDecoration: "underline",
        fontSize: 24,
        lineHeight: "40px",
        fontWeight: "normal",
      },
    },
    button: {
      padding: "8px 16px",
      fontSize: 16,
      borderRadius: 12,
      textAlign: "center",
      marginTop: 48,
      color: theme.palette.text.white,
      cursor: "pointer",
      textDecoration: "none",

      "& span": {
        display: "inline-block",
      },
    },
    nextButton: {
      padding: "8px 16px",
      fontSize: 16,
      borderRadius: 12,
      textAlign: "center",
      marginTop: 24,
      color: "#75038A",
      border: "1px solid #75038A",
      cursor: "pointer",
      transition: "all 0.3s ease-out",
      userSelect: "none",

      "&:hover": {
        backgroundColor: "#75038A",
        color: "#fff",
      },
    },
    scheduleButton: {
      display: "inline-block",
      textDecoration: "underline",
      fontSize: 24,
      lineHeight: "40px",
      fontWeight: "normal",
      cursor: "pointer",
    },
    contactsFooter: {
      display: "flex",
      justifyContent: "space-between"
    },
    contactsLinks: {
      "& a": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 400,
        borderBottom: "1px solid #ffffff",

        "&:hover": {
          opacity: 0.8
        },

        "&:not(:last-child)": {
          marginRight: 56
        }
      }
    },
    copyright: {
      position: "absolute",
      bottom: 32,
      left: 0,
      color: "#373340",
      fontWeight: 500,
      zIndex: 1,
    },
    copyrightWhite: {
      color: "#ffffff"
    },
    introduceSlide: {
      maxWidth: 856
    },
    introduceLogo: {
      marginBottom: 70,

      "& svg": {
        display: "block"
      }
    },
    introduceTitle: {
      marginBottom: 48
    },
    introduceText: {
      color: "#373340",
      fontWeight: 400
    },
    slideLogo: {
      position: "absolute",
      right: 0,
      top: 0
    },
    nextButtonExplain: {
      display: "inline-block",
      padding: "8px 16px",
      fontSize: 64,
      fontWeight: 700,

      border: "3px solid #FF2C9C",
      borderRadius: "20px",
      cursor: "pointer",

      textAlign: "center",
      marginTop: 24,
      color: "#FF2C9C",
      transition: "all 0.3s ease-out",
      userSelect: "none",

      "&:hover": {
        backgroundColor: "#FF2C9C",
        color: "#fff",
      },

      "&:active": {
        backgroundColor: "#E20279",
        color: "#fff",
      },
    },
    phaseImgTop: {
      position: "absolute",
      userSelect: "none",
      left: 0,
      top: 48,
      zIndex: -1,
    },
    phaseImgTopMobile: {
      width: 360,
      height: 160,

      "& svg": {
        width: "100%",
        height: "100%"
      }
    },
    swipeBlock: {
      fontSize: "32px",
      lineHeight: "40px",
      width: "100%",
      marginBottom: "48px",
      textAlign: "left",
      position: "relative"
    },
    arrowRight: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(0, -50%)",
      width: 100,
      height: 100,

      "& svg": {
        width: "100%",
        height: "100%"
      }
    },
    swipeBlockTitle: {
      fontSize: 100,
      lineHeight: "140px",
      marginBottom: 10
    },
    swipeBlockText: {
      color: "#75038A",
      fontWeight: 400,
      fontSize: 60,
      lineHeight: "80px"
    },
    astraloadSvgImage: {
      userSelect: "none",
      position: "absolute",
      bottom: 0,
      right: 0,
      left: 0
    }
  }
}

// eslint-disable-next-line
AstraloadFrontendPresentation = withWidth()(withStyles(styles)(AstraloadFrontendPresentation))

export { AstraloadFrontendPresentation }
