import React from "react"

function PresentationAstraloadLogo({ className, fill = "#000000"}) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="51" viewBox="0 0 29 51" fill="none">
        <path d="M14.2058 49.7948V18.0967L1.5986 26.5196C0.24886 27.4206 0.367835 29.3123 1.69296 30.2378C4.0338 31.8409 6.04345 33.8753 7.61296 36.2308C10.1237 39.8593 11.2724 45.5466 12.3883 50.019C12.4183 50.2585 12.5427 50.4764 12.7343 50.6247C12.9259 50.773 13.1689 50.8396 13.4099 50.8099C13.6509 50.7802 13.8701 50.6565 14.0194 50.4661C14.1687 50.2758 14.2357 50.0343 14.2058 49.7948Z" fill={fill}/>
        <path d="M8.86008 19.7846C10.4575 19.7846 11.7524 18.4978 11.7524 16.9104C11.7524 15.323 10.4575 14.0361 8.86008 14.0361C7.2627 14.0361 5.96777 15.323 5.96777 16.9104C5.96777 18.4978 7.2627 19.7846 8.86008 19.7846Z" fill={fill}/>
        <path d="M28.9994 7.5256C29.0004 6.17079 28.6219 4.84246 27.9062 3.68935C27.1905 2.53624 26.1659 1.60388 24.9472 0.996666C23.7284 0.389456 22.3637 0.131372 21.0057 0.251315C19.6477 0.371258 18.3502 0.864493 17.2583 1.67579C16.1665 2.48708 15.3234 3.5844 14.8236 4.84487C14.3238 6.10535 14.1869 7.4792 14.4283 8.8126C14.6697 10.146 15.2799 11.3863 16.1905 12.3946C17.1011 13.4029 18.2761 14.1394 19.5841 14.5216L5.30713 24.0412V49.7911C5.30917 50.0159 5.39458 50.232 5.54699 50.3981C5.69941 50.5642 5.90813 50.6687 6.13319 50.6914C6.35825 50.7141 6.58384 50.6535 6.76676 50.5213C6.94968 50.389 7.07708 50.1943 7.12457 49.9745C8.53585 43.0438 11.1861 33.5608 13.4261 28.6481C15.2969 24.5468 18.3984 20.2497 21.8815 16.1768C22.3083 15.6673 22.8137 15.2284 23.3789 14.8763C24.3143 14.3911 25.0199 14.0038 25.1553 13.9386H25.1799C26.3377 13.3093 27.3034 12.3816 27.9755 11.2531C28.6476 10.1246 29.0013 8.83695 28.9994 7.5256Z" fill={fill}/>
      </svg>
    </div>
  )
}

export { PresentationAstraloadLogo }
