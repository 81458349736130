import React from "react"

function AstraloadLogo({ className, width = "146", height = "66" }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="57" height="95" viewBox="0 0 57 95" fill="none">
        <path d="M27.0849 91.0488V34.2314L4.62838 49.3291C2.22415 50.9441 2.43607 54.3349 4.79646 55.9938C8.96607 58.8672 12.5458 62.5139 15.3415 66.7361C19.8138 73.2399 21.8599 83.4341 23.8476 91.4507C23.9009 91.88 24.1226 92.2705 24.4638 92.5364C24.8051 92.8022 25.2379 92.9217 25.6672 92.8684C26.0965 92.8151 26.4871 92.5934 26.7529 92.2522C27.0188 91.9109 27.1382 91.4781 27.0849 91.0488Z" fill="#640099"/>
        <path d="M17.5631 37.257C20.4084 37.257 22.715 34.9504 22.715 32.105C22.715 29.2597 20.4084 26.9531 17.5631 26.9531C14.7177 26.9531 12.4111 29.2597 12.4111 32.105C12.4111 34.9504 14.7177 37.257 17.5631 37.257Z" fill="url(#paint0_linear_6783_19720)"/>
        <path d="M53.4363 15.2826C53.438 12.8541 52.7637 10.4732 51.4889 8.40627C50.2141 6.33937 48.3891 4.66816 46.2182 3.57976C44.0473 2.49136 41.6164 2.02876 39.1975 2.24375C36.7786 2.45875 34.4673 3.34284 32.5224 4.79705C30.5775 6.25126 29.0759 8.21815 28.1856 10.4775C27.2953 12.7368 27.0515 15.1994 27.4815 17.5894C27.9115 19.9795 28.9983 22.2027 30.6203 24.01C32.2423 25.8173 34.3354 27.1374 36.6651 27.8226L11.2344 44.886V91.0414C11.238 91.4443 11.3901 91.8318 11.6616 92.1295C11.9331 92.4273 12.3049 92.6145 12.7058 92.6552C13.1067 92.6959 13.5085 92.5873 13.8343 92.3502C14.1602 92.1132 14.3871 91.7642 14.4717 91.3703C16.9855 78.9472 21.7063 61.9495 25.6963 53.1437C29.0286 45.7922 34.5532 38.0899 40.7575 30.7895C41.5176 29.8762 42.4179 29.0894 43.4248 28.4583C45.0909 27.5887 46.3478 26.8945 46.589 26.7776H46.6328C48.695 25.6496 50.4152 23.9867 51.6124 21.9639C52.8096 19.9411 53.4397 17.6331 53.4363 15.2826Z" fill="url(#paint1_linear_6783_19720)"/>
        <defs>
          <linearGradient id="paint0_linear_6783_19720" x1="24.0669" y1="25.6012" x2="11.0227" y2="38.6454" gradientUnits="userSpaceOnUse">
            <stop stop-color="#ED1C24"/>
            <stop offset="1" stop-color="#842AD0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_6783_19720" x1="28.4951" y1="-11.6104" x2="-5.14965" y2="-3.84865" gradientUnits="userSpaceOnUse">
            <stop stop-color="#ED1C24"/>
            <stop offset="1" stop-color="#842AD0"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export { AstraloadLogo }
